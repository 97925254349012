import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Navbar.module.css';

const NavBar = () => {
    
    let tokenAuth = localStorage.getItem('token');
    // let firstname = localStorage.getItem('firstname');
    // let lastname = localStorage.getItem('lastname');
    return (
        <>
        <nav className={["navbar fixed-top navbar-expand-lg navbar-light", styles.navBarI].join(' ')}>
            <div className="container">
                <Link 
                    className="navbar-brand btn btn-orange" 
                    to="/"
                >
                    <i className="fas fa-user-graduate"></i>
                    &nbsp;
                    <b className={styles.brandName}>Students</b>
                </Link>
                <ul className="nav navbar-nav navbar-right">
                    {!tokenAuth ?
                    ""
                    :
                        <li>
                            <Link 
                                className="btn btn-orange"
                                to="/" 
                                title="Home" 
                            >
                                <i className="fas fa-home"></i>
                            </Link>
                            <Link 
                                className="btn btn-orange mx-2" 
                                to="/user" 
                                title="Setting" 
                            >
                                <i className="fas fa-cog"></i>
                            </Link>
                            <button 
                                className="btn btn-orange"
                                title="LogOut"
                                onClick={async () => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                }}
                            >
                                <i className="fas fa-power-off"></i>
                            </button>
                        </li>
                    }
                </ul>
            </div>
        </nav>
        </>
    );
};

export default NavBar;