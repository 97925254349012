import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    return (
      <footer className={styles.footer}>
         <div className="container">
            <div className="row">
               <div className="col">
                 <p> © 2021 Student.UmasterIT All Rights Reserved</p>
               </div>
            </div>
         </div>
      </footer>
    );
};

export default Footer;